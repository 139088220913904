import React, { useCallback, useMemo, useState } from 'react'
import {
  useGetProjectPermissionsByIdQuery,
  useGetUserProfileQuery,
} from '../../../../redux/api-slice'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../../redux/application-slice'
import { POSTHOG } from '../../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Mention, MentionsInput } from 'react-mentions'
import { getMentions } from '../../../../utils/match-mentions'
import {
  ProjectRisk,
  ProjectRiskComment,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import {
  useCreateProjectRiskCommentMutation,
  useUpdateProjectRiskCommentMutation,
} from '../../../../redux/api/project-risk-api-slice'
import ProjectRiskPopover from '../project-risk-comment-popover'
import { selectRiskWorkflowFilterState } from '../../../../redux/workflow-slice'

interface RiskReviewCardCommentProps {
  projectRisk: ProjectRisk
  commentSelected: ProjectRiskComment | null
  setCommentSelected: (comment: ProjectRiskComment | null) => void
  onCommentCommit?: () => void
  onSelectProjectRisk?: () => void
}

const RiskReviewCardComment: React.FC<RiskReviewCardCommentProps> = ({
  projectRisk,
  commentSelected,
  setCommentSelected,
  onCommentCommit,
  onSelectProjectRisk,
}) => {
  const posthog = usePostHog()
  const currentProject = useSelector(selectCurrentProject)
  const [localEditComment, setLocalEditComment] = useState('')
  const [localEditAddComment, setLocalEditAddComment] = useState('')
  const { data: profile } = useGetUserProfileQuery(undefined)
  const [updateProjectRisksComment] = useUpdateProjectRiskCommentMutation()
  const [addProjectRiskComment] = useCreateProjectRiskCommentMutation()
  const reset = useCallback(() => {
    setLocalEditComment('')
    setLocalEditAddComment('')
    setCommentSelected(null)
  }, [setCommentSelected])
  const [commentsExpanded, setCommentsExpanded] = useState(false)
  const { data: permissions } = useGetProjectPermissionsByIdQuery(
    currentProject?.id ?? skipToken
  )
  const projectRiskFilterState = useSelector(selectRiskWorkflowFilterState)

  const onUpdateComment = useCallback(() => {
    reset()
    const mentions = getMentions(localEditComment)
    updateProjectRisksComment({
      ...commentSelected,
      comment: localEditComment,
      project_uuid: currentProject?.uuid,
      parent: projectRisk,
      user: profile,
      mentions,
      sort: projectRiskFilterState?.sort,
    })
    onCommentCommit?.()
  }, [
    reset,
    localEditComment,
    updateProjectRisksComment,
    commentSelected,
    currentProject?.uuid,
    projectRisk,
    profile,
    projectRiskFilterState?.sort,
    onCommentCommit,
  ])

  const onCommentsExpandClick = useCallback(() => {
    setCommentsExpanded(true)
    onCommentCommit?.()
    onSelectProjectRisk?.()
  }, [onCommentCommit, onSelectProjectRisk])
  const onSaveComment = useCallback(() => {
    if (!projectRisk || !projectRisk?.id) {
      return
    }

    posthog?.capture(POSTHOG.project_risk_comment_edited)
    const mentions = getMentions(localEditAddComment)

    addProjectRiskComment({
      comment: localEditAddComment,
      project_risk_id: projectRisk?.id,
      parent: projectRisk,
      project_uuid: currentProject?.uuid,
      user_created: profile?.id,
      user: profile,
      mentions,
      sort: projectRiskFilterState?.sort,
    })
    onCommentCommit?.()
    onCommentsExpandClick()
    reset()
  }, [
    projectRisk,
    posthog,
    localEditAddComment,
    addProjectRiskComment,
    currentProject?.uuid,
    profile,
    projectRiskFilterState?.sort,
    onCommentCommit,
    onCommentsExpandClick,
    reset,
  ])
  const commentsToDisplay: ProjectRiskComment[] = useMemo(() => {
    const commentsToDisplay = commentsExpanded
      ? projectRisk.comments
      : projectRisk.comments?.slice(0, 5)
    return commentsToDisplay ?? []
  }, [commentsExpanded, projectRisk.comments])

  const onCommentSelect = useCallback(
    (comment) => {
      setLocalEditComment(comment.comment ?? '')
      setCommentSelected(comment)
    },
    [setCommentSelected]
  )

  const onKeyDownSave = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onSaveComment()
      } else if (e.key === 'Enter' && e.shiftKey) {
        setLocalEditAddComment(`${localEditAddComment}\n`)
      } else if (e.key === 'Escape') {
        reset()
      }
    },
    [reset, localEditAddComment, onSaveComment]
  )

  const onKeyDownUpdate = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onUpdateComment()
      } else if (e.key === 'Enter' && e.shiftKey) {
        setLocalEditComment(`${localEditComment}\n`)
      } else if (e.key === 'Escape') {
        reset()
      }
    },
    [localEditComment, onUpdateComment, reset]
  )

  const onChangeLocalEditComment = useCallback((e) => {
    setLocalEditComment(e.target.value)
  }, [])

  const onLocalEditCommentAdd = useCallback((e) => {
    if (e.target.value.slice(-1) !== '\n') {
      setLocalEditAddComment(e.target.value)
    }
  }, [])

  const renderSuggestion = useCallback((_, __, highlightedDisplay) => {
    return <div>{highlightedDisplay}</div>
  }, [])

  const renderComment = useCallback((comment: ProjectRiskComment) => {
    const mentions = comment.mentions ?? []
    const elements: JSX.Element[] = []
    let index = 0
    if (!mentions || mentions.length === 0) {
      return <span>{comment.comment}</span>
    }
    for (const mention of mentions) {
      if (
        mention.start_index === undefined ||
        mention.end_index === undefined
      ) {
        continue
      }
      elements.push(
        <span key={`mention_${mention.user_targeted_id}_${index}`}>
          {comment.comment?.slice(index, mention.start_index)}{' '}
        </span>
      )
      elements.push(
        <span
          key={`mention_${mention.user_targeted_id}_${index}_mention`}
          className="text-indigo-500"
        >
          {mention.user_targeted?.email}{' '}
        </span>
      )
      index = mention.end_index
    }
    elements.push(
      <span key={`mention_${mentions.length}_${index}`}>
        {comment.comment?.slice(index)}
      </span>
    )
    return elements
  }, [])

  return (
    <div className="flex w-full flex-col">
      {commentsToDisplay?.map((comment) => (
        <div
          key={`project_risk_comments_${projectRisk.id}_${comment.id}`}
          className="mt-2 flex justify-between border-b border-gray-200 pb-2"
        >
          <div className="w-full">
            <div className="flex items-center space-x-1 text-sm">
              <div>
                <div className="font-semibold">
                  {comment?.user?.first_name} {comment?.user?.last_name}
                </div>
              </div>
              <span className="text-md text-gray-400">
                {'• '}
                {new Date(comment?.date_created ?? '')
                  .toDateString()
                  .split(' ')
                  .slice(1)
                  .join(' ')}
              </span>
            </div>
            {commentSelected?.id === comment.id && comment.id ? (
              <div className="my-2 w-full">
                <MentionsInput
                  className={'mentions'}
                  placeholder="Add a comment..."
                  onChange={onChangeLocalEditComment}
                  value={localEditComment}
                  onKeyDown={onKeyDownUpdate}
                >
                  <Mention
                    trigger="@"
                    appendSpaceOnAdd
                    data={
                      permissions?.users
                        ?.filter?.((u) => u.email)
                        ?.map((u) => {
                          return {
                            id: u.id,
                            display: u.email,
                          }
                        }) ?? []
                    }
                    renderSuggestion={renderSuggestion}
                  />
                </MentionsInput>
              </div>
            ) : (
              <div
                className={'whitespace-pre-wrap break-all p-1 pl-0 text-sm'}
                key={`project_risk_comment_${comment?.id}`}
              >
                {renderComment(comment)}
              </div>
            )}
          </div>
          {profile?.id === comment.user?.id && comment.id && (
            <ProjectRiskPopover
              setCommentSelected={onCommentSelect}
              projectRiskComment={comment}
              projectRisk={projectRisk}
              projectId={currentProject?.uuid}
            />
          )}
        </div>
      ))}
      {(projectRisk?.comments?.length ?? 0) > 5 && !commentsExpanded && (
        <button
          onClick={onCommentsExpandClick}
          className="p-1 text-left text-xs text-indigo-500 hover:text-indigo-800"
        >
          {(projectRisk?.comments?.length ?? 0) - 5} more comment
          {projectRisk?.comments?.length === 2 ? '' : 's'}
        </button>
      )}
      <div className="mt-2">
        <MentionsInput
          data-testid="risk-card-add-comment-input"
          className={'mentions'}
          onChange={onLocalEditCommentAdd}
          placeholder="+ Add a new comment..."
          value={localEditAddComment}
          onKeyDown={onKeyDownSave}
        >
          <Mention
            trigger="@"
            appendSpaceOnAdd
            data={
              permissions?.users
                ?.filter?.((u) => u.email)
                ?.map((u) => {
                  return {
                    id: u.id,
                    display: u.email,
                  }
                }) ?? []
            }
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>
      </div>
    </div>
  )
}

export default RiskReviewCardComment

import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react'
import {
  selectSearchPage,
  selectSearchQuery,
  selectSearchResults,
  selectSelectedResult,
  setFilterDocumentUuids,
  setSearchPage,
  setSearchQuery,
  setSelectedResult,
} from '../../redux/search-slice'
import { useDispatch, useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import {
  setTemporaryHighlight,
  selectCurrentPage,
  selectIsSearching,
} from '../../redux/viewer-slice'
import TextareaAutosize from 'react-textarea-autosize'
import { useHotkeys } from 'react-hotkeys-hook'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import DocumentListboxMulti from '../document-listbox/document-listbox-multi'
import { Hit } from '../../shared/interfaces/search-results.interface'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import parse from 'html-react-parser'
import { Route, useBackButton } from '../../hooks/use-back-button'
import { useLocation } from 'react-router-dom'
import { useGetDocumentsListByProjectQuery } from '../../redux/api-slice'
import { Loader } from '@mantine/core'

interface TypesearchSearchProps {
  query?: string
  open?: boolean
}

const TypesenseSearch: React.FC<TypesearchSearchProps> = () => {
  const currentProject = useSelector(selectCurrentProject)
  const searchQuery = useSelector(selectSearchQuery)
  const currentPage = useSelector(selectCurrentPage)
  const dispatch = useDispatch()
  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const searchResults = useSelector(selectSearchResults)
  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[] | null
  >(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [highlightedResult, setHighlightedResult] = useState<Hit | null>(null)
  const documentViewerContext = useContext(DocumentViewerContext)
  const currentDocument = useSelector(selectCurrentDocument)
  const { documentViewer } = documentViewerContext
  const selectedSearchResult = useSelector(selectSelectedResult)
  const isSearching = useSelector(selectIsSearching)
  const posthog = usePostHog()
  const searchPage = useSelector(selectSearchPage)
  const { addToNavigationHistoryAndNavigate } = useBackButton()
  const { pathname } = useLocation()

  const title = useMemo(() => {
    if (!highlightedResult) {
      return ''
    }
    return documents?.find(
      (doc: ProjectDocumentMetadata) =>
        doc.uuid === highlightedResult.document.document_uuid
    )?.title
  }, [documents, highlightedResult])

  const onResultClick = useCallback(
    (result: Hit) => {
      posthog?.capture(POSTHOG.document_typesense_result_clicked, {
        query: searchQuery,
        document_uuid: currentDocument?.uuid,
        result_page: result.document.page,
      })

      dispatch(setSelectedResult(result))
      const route = pathname.split('/')[2]
      if (!route || !result.document.document_uuid) {
        return
      }
      addToNavigationHistoryAndNavigate(
        route as Route,
        route as Route,
        result.document.document_uuid,
        result.document.page
      )
    },
    [
      addToNavigationHistoryAndNavigate,
      currentDocument?.uuid,
      dispatch,
      pathname,
      posthog,
      searchQuery,
    ]
  )

  useEffect(() => {
    if (searchPage !== 1) {
      return
    }
    setCurrentIndex(0)
    if (!searchResults || !searchResults?.hits?.length) {
      setHighlightedResult(null)
    } else {
      setHighlightedResult(searchResults?.hits[0])
      onResultClick(searchResults?.hits[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, searchPage])

  const onSearchResult = useCallback(
    (result) => {
      const currentSearchResults = documentViewer?.getPageSearchResults()
      if (!currentSearchResults) {
        documentViewer?.displaySearchResult(result)
      } else {
        documentViewer?.displayAdditionalSearchResult(result)
      }
    },
    [documentViewer]
  )

  const onTriggerSearch = useCallback(
    (isReverse = false) => {
      if (!documentViewer) {
        return
      }
      let mode =
        window.Core.Search.Mode.HIGHLIGHT | window.Core.Search.Mode.PAGE_STOP
      if (isReverse) {
        mode |= window.Core.Search.Mode.SEARCH_UP
      }

      const searchOptions: {
        fullSearch?: boolean
        onResult?: (...params: any[]) => any
        onPageEnd?: (...params: any[]) => any
        onDocumentEnd?: (...params: any[]) => any
        onError?: (...params: any[]) => any
        startPage?: number
        endPage?: number
      } = {
        fullSearch: true,
        onResult: onSearchResult,
        startPage: currentPage,
        endPage: currentPage,
      }
      documentViewer.textSearchInit(searchQuery, mode, searchOptions)
    },
    [currentPage, documentViewer, onSearchResult, searchQuery]
  )

  useEffect(() => {
    documentViewer?.clearSearchResults()
  }, [documentViewer, searchQuery])

  useEffect(() => {
    if (!documentViewer) {
      return
    }
    onTriggerSearch()
  }, [documentViewer, onTriggerSearch])

  const onSelectNextResult = useCallback(() => {
    if (!searchResults?.hits) {
      return
    }
    const index = searchResults?.hits
      .map((item) => item.document.id)
      .indexOf(highlightedResult?.document?.id ?? '')
    if (index < searchResults?.hits.length - 1) {
      setHighlightedResult(searchResults?.hits[index + 1])
      setCurrentIndex(index + 1)
      onResultClick(searchResults?.hits[index + 1])
    }
    if (index === searchResults?.hits.length - 1) {
      dispatch(setSearchPage(searchPage + 1))
    }
    onTriggerSearch()
  }, [
    dispatch,
    highlightedResult?.document?.id,
    onResultClick,
    searchPage,
    searchResults?.hits,
    onTriggerSearch,
  ])

  const onSelectPreviousResult = useCallback(() => {
    if (!searchResults?.hits) {
      return
    }
    const index = searchResults?.hits
      .map((item) => item.document.id)
      .indexOf(highlightedResult?.document?.id ?? '')
    if (index > 0) {
      setCurrentIndex(index - 1)
      setHighlightedResult(searchResults?.hits[index - 1])
      onResultClick(searchResults?.hits[index - 1])
    }
    onTriggerSearch()
  }, [
    highlightedResult?.document?.id,
    onResultClick,
    onTriggerSearch,
    searchResults?.hits,
  ])

  useHotkeys('down', onSelectNextResult, { preventDefault: true })
  useHotkeys('up', onSelectPreviousResult, { preventDefault: true })
  useHotkeys('tab', onSelectNextResult, { preventDefault: true })
  useHotkeys('enter', onSelectNextResult, { preventDefault: true })
  useHotkeys('shift+enter', onSelectPreviousResult, { preventDefault: true })

  const setProjectDocumentFilter = useCallback(
    (projectDocuments: ProjectDocumentMetadata[]) => {
      setSelectedDocuments(projectDocuments)
      if (!projectDocuments?.length) {
        dispatch(setFilterDocumentUuids([]))
        return
      }
      dispatch(setFilterDocumentUuids(projectDocuments.map((d) => d.uuid)))
    },
    [dispatch]
  )

  const onSearchKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        onSelectNextResult()
      } else if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault()
        onSelectPreviousResult()
      }
    },
    [onSelectNextResult, onSelectPreviousResult]
  )

  const onChangeSearch = useCallback(
    (e) => {
      dispatch(setSearchQuery(e.target.value))
    },
    [dispatch]
  )

  const onResultLoseFocus = () => {
    dispatch(setTemporaryHighlight(null))
  }

  const onRowResultClick = useCallback(() => {
    if (!highlightedResult) {
      return
    }
    onResultClick(highlightedResult)
  }, [highlightedResult, onResultClick])

  return (
    <div
      className={
        'mt-1 flex h-full min-h-0 min-w-0 flex-grow flex-col space-y-2 overflow-visible rounded p-3 px-2'
      }
    >
      <DocumentListboxMulti
        documents={documents}
        setSelectedDocuments={setProjectDocumentFilter}
        selectedDocuments={selectedDocuments ?? []}
        customWidth={'w-96'}
      />
      <div className="relative flex items-center">
        <TextareaAutosize
          name="search"
          id="search"
          className="block w-full resize-none rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={'Search'}
          value={searchQuery}
          onKeyDown={onSearchKeyDown}
          onChange={onChangeSearch}
        />
      </div>
      <div className={'flex h-full flex-1 flex-col overflow-hidden'}>
        {!searchQuery || (searchQuery && !searchResults) ? (
          <div className={'mt-9 text-center text-xs text-gray-400'}>
            Enter a search term
          </div>
        ) : (
          <div className="flex h-full flex-col space-y-2 focus-visible:outline-0">
            <div className="flex justify-between space-x-2 text-xs font-semibold text-gray-900">
              <button
                disabled={isSearching}
                onClick={onSelectPreviousResult}
                className="w-full rounded bg-gray-300 py-1"
              >
                Previous
              </button>
              <button
                disabled={isSearching}
                onClick={onSelectNextResult}
                className="flex w-full items-center justify-between rounded bg-gray-300 py-1"
              >
                <span></span>
                <div className="ml-2">Next</div>
                {isSearching ? (
                  <Loader size="12px" color="orange" className="mr-2" />
                ) : (
                  <span className="h-4 w-4"></span>
                )}
              </button>
            </div>
            {highlightedResult && (
              <button
                className={`flex w-full cursor-pointer space-y-1 border-b px-1 py-1.5 text-left ${
                  selectedSearchResult?.document.id ===
                  highlightedResult.document.id
                    ? 'bg-blue-100'
                    : ''
                }`}
                onClick={onRowResultClick}
                // onFocus={onRowResultClick}
                onBlur={onResultLoseFocus}
              >
                <div>
                  <div className={'text-xs'}>
                    {parse(highlightedResult?.highlight?.text?.snippet ?? '')}
                  </div>
                  <div
                    className={
                      'flex justify-between gap-3 text-xs text-gray-400'
                    }
                  >
                    <div title={title}>{title}</div>
                    <div className={'flex-shrink-0'}>
                      Page {highlightedResult?.document?.page}
                    </div>
                  </div>
                </div>
              </button>
            )}
            <div className={'text-center text-xs'}>
              {searchResults?.found ? (
                <>
                  {currentIndex + 1} / {searchResults.found} results
                </>
              ) : (
                <>No results found</>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TypesenseSearch

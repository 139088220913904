import { Popover } from '@headlessui/react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import React, { useState, useMemo, useCallback } from 'react'
import { usePopper } from 'react-popper'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import {
  useGetUserProfileQuery,
  useGetUserPermissionsQuery,
} from '../../../redux/api-slice'
import { Tooltip } from 'react-tooltip'
import { useNavigate, useLocation } from 'react-router-dom'
import CustomRiskListsButton from '../risk-review/custom-risk-list/custom-risk-lists-button'

interface ExportPopoverProps {
  onClickExportToExcel?: () => void
  onClickExportToPDF?: () => void
  exportTypes: ('pdf' | 'excel')[]
  isDisabled?: boolean
  compact?: boolean
}

const ExportPopover: React.FC<ExportPopoverProps> = ({
  onClickExportToExcel,
  onClickExportToPDF,
  isDisabled = false,
  exportTypes,
  compact = false,
}) => {
  const [exportButtonRef, setExportButtonRef] =
    useState<HTMLButtonElement | null>(null)
  const [exportPanelRef, setExportPanelRef] = useState<HTMLDivElement | null>(
    null
  )
  const { styles: exportStyles, attributes: exportAttributes } = usePopper(
    exportButtonRef,
    exportPanelRef
  )
  const navigate = useNavigate()
  const location = useLocation()
  const { data: user } = useGetUserProfileQuery(undefined)
  const { data: userPermissions } = useGetUserPermissionsQuery()
  const custom_risk_lists_flag = user?.feature_flags?.custom_risk_lists
  const hasCustomRiskListPermission = useMemo(
    () =>
      userPermissions?.includes('api.create_customrisklisttemplate') || false,
    [userPermissions]
  )

  const handleExportToExcel = useCallback(
    (close: () => void) => {
      onClickExportToExcel?.()
      close()
    },
    [onClickExportToExcel]
  )

  const handleExportToPDF = useCallback(
    (close: () => void) => {
      onClickExportToPDF?.()
      close()
    },
    [onClickExportToPDF]
  )

  const handleCreateCustomRiskList = useCallback(
    (close: () => void) => {
      if (hasCustomRiskListPermission) {
        if (!location.pathname.endsWith('/customrisklists')) {
          navigate(location.pathname + '/customrisklists')
        }
        close()
      }
    },
    [hasCustomRiskListPermission, navigate, location.pathname]
  )

  return (
    <>
      <Popover className="relative flex items-center justify-center">
        {({ close }) => (
          <>
            <Popover.Button
              className={`rounded ${
                !isDisabled ? ' bg-white hover:bg-gray-100' : 'bg-white'
              }`}
              tabIndex={-1}
              ref={setExportButtonRef}
              disabled={isDisabled}
              aria-label="Additional options"
              data-tooltip-id={!isDisabled ? '' : `export-questions-id`}
              data-tooltip-content="Documents are still processing"
            >
              {compact ? (
                <div className={'p-1'}>
                  <EllipsisVerticalIcon width={24} />
                </div>
              ) : (
                <div
                  className={`flex rounded px-2.5 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${
                    !isDisabled
                      ? ' bg-white hover:bg-gray-100'
                      : ' bg-gray-200 '
                  }'}`}
                >
                  <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
                  Export
                </div>
              )}
            </Popover.Button>
            <Popover.Panel
              ref={setExportPanelRef}
              style={exportStyles.popper}
              {...exportAttributes.popper}
              className="z-50 rounded border bg-white shadow"
            >
              {exportTypes.includes('excel') && (
                <button
                  className="w-36 px-4 py-2 text-left hover:bg-gray-100"
                  onClick={() => handleExportToExcel(close)}
                >
                  Export to Excel
                </button>
              )}
              {exportTypes.includes('pdf') && (
                <button
                  className="w-36 px-4 py-2 text-left hover:bg-gray-100"
                  onClick={() => handleExportToPDF(close)}
                >
                  Export to PDF
                </button>
              )}
              {custom_risk_lists_flag && (
                <CustomRiskListsButton
                  hasCustomRiskListPermission={hasCustomRiskListPermission}
                  onClick={() => handleCreateCustomRiskList(close)}
                />
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
      <Tooltip id="export-questions-id" />
    </>
  )
}

export default ExportPopover

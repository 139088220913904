import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../../redux/application-slice'
import { MODAL_TYPES } from '../../../modals/modal-controller'
import { userPermissionsOptions } from '../../../../shared/interfaces/project/risk/risk-inteface'

interface RiskProps {
  id: string
  riskName: string
  queryText: string
  permissions?: userPermissionsOptions
  isLoadingRiskLists?: boolean
}

const Risk: React.FC<RiskProps> = ({ id, riskName, queryText }) => {
  const dispatch = useDispatch()

  const handleEditClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.EDIT_CUSTOM_RISK,
        props: { id, riskName, queryText },
      })
    )
  }

  const handleDeleteClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.DELETE_CUSTOM_RISK,
        props: {
          id,
          risk_name: riskName,
        },
      })
    )
  }

  const buttonBaseClasses =
    'rounded-md border-2 border-[#ffffff00] px-3 py-1 text-sm font-medium text-gray-500 transition-colors hover:border-blue-400 hover:bg-blue-50 hover:!text-blue-600 group-hover:text-black'

  return (
    <div className="group">
      <div className="mb-2 flex w-full max-w-full items-center justify-between gap-x-2 rounded-lg border-2 border-gray-200 p-4 transition-colors group-hover:border-blue-500">
        <div className="min-w-0 flex-grow">
          <div className="truncate text-lg font-medium">{riskName}</div>
          <div className="text-sm text-gray-600">{queryText}</div>
        </div>
        <div className="flex shrink-0 space-x-2">
          <button
            onClick={handleEditClick}
            // className="rounded px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
            className={buttonBaseClasses}
            aria-label="Edit Details"
          >
            Edit Details
          </button>
          <button
            onClick={handleDeleteClick}
            // className="rounded px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
            className={buttonBaseClasses}
            aria-label="Delete"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default Risk

import React, { useCallback, useMemo } from 'react'
import {
  ChatHistory,
  ChatSessionMessage,
  ChatSessionMessageSource,
} from '../../../shared/interfaces/chat/chat-history.interface'
import AIChatSourceMessageButton from './ai-chat-source-message-button'
import AIChatSourceMessageButtonV2 from './ai-chat-message-sources-v2'
import AIChatGroupedSource from './ai-chat-grouped-source'
import AiChatSource from './ai-chat-source'
import AIChatFeedbackButton from './ai-chat-feedback-button'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import _ from 'lodash'
import AIChatConflict from './ai-chat-conflict'
import { useGetUserProfileQuery } from '../../../redux/api-slice'

import AiChatQuestionPopover from './ai-chat-question-popover'

interface AIChatMessageProps {
  chatHistoryMessage: ChatSessionMessage
  index: number
  selectedChatHistory: ChatHistory | null
  documents: ProjectDocumentMetadata[]
  sessionUuid: string
  query: string
  writing: boolean
  isLast: boolean
}

const AIChatMessage: React.FC<AIChatMessageProps> = ({
  chatHistoryMessage,
  index,
  selectedChatHistory,
  documents,
  sessionUuid,
  query,
  writing,
  isLast,
}) => {
  const { data: user } = useGetUserProfileQuery(undefined)
  const paragraphs = useMemo(() => {
    const paragraphs = chatHistoryMessage.message
      .split('\n')
      .map((p) => `${p}\n`)
    return paragraphs
  }, [chatHistoryMessage])

  const source = useMemo(() => {
    return chatHistoryMessage.origin === 'USER' ? 'user' : 'system'
  }, [chatHistoryMessage])

  const isMessageComplete = useCallback(() => {
    if (!isLast) {
      return true
    }
    return !writing
  }, [writing, isLast])
  const sources_v2_flag = user?.feature_flags?.sources_v2_chat

  return (
    <div
      key={`${chatHistoryMessage.message}${index}`}
      className={`relative flex items-center ${
        source === 'user' ? 'justify-start' : 'justify-end'
      } pb-3`}
    >
      <div className="relative w-10/12 rounded bg-white">
        <div
          className={`whitespace-pre-line p-3 text-sm ${
            source === 'user' ? 'rounded bg-white' : 'rounded-t bg-blue-200'
          }`}
        >
          {sources_v2_flag ? (
            <AIChatSourceMessageButtonV2
              chatHistoryMessage={chatHistoryMessage}
            />
          ) : (
            paragraphs.map((message, paragraphIndex) => {
              const currentMessage = paragraphIndex
              const relevantSources =
                chatHistoryMessage.message_sources?.filter((s) =>
                  message.includes(s.text ?? 'NO SOURCES FOUND FOR THIS')
                )
              const indexOfParagraphMatch =
                chatHistoryMessage.message_sources?.findIndex((s) =>
                  message.includes(s.text ?? 'NO SOURCES FOUND FOR THIS')
                ) ?? 0
              return (
                <div key={`${message}${currentMessage}`}>
                  <span>{message.replace('\n', '')}</span>
                  {relevantSources?.map(
                    (s: ChatSessionMessageSource, sourceIndex) => {
                      return (
                        <AIChatSourceMessageButton
                          key={s.id}
                          chatSessionMessageSource={s}
                          sources={relevantSources}
                          sourceIndex={sourceIndex}
                          indexOfParagraphMatch={indexOfParagraphMatch}
                        />
                      )
                    }
                  )}
                  {'\n'}
                </div>
              )
            })
          )}
          {selectedChatHistory &&
            selectedChatHistory?.messages?.[index] &&
            selectedChatHistory?.messages?.every(
              (m) => !m.message_sources?.find((s) => s.text)
            ) &&
            (chatHistoryMessage.message_sources ?? [])?.length > 0 && (
              <div
                className={
                  'mt-1 flex grow-0 flex-col items-end gap-1 border-t pt-1 text-xs'
                }
              >
                {chatHistoryMessage.message_sources &&
                  Object.keys(
                    _.groupBy(
                      chatHistoryMessage.message_sources,
                      'source_document_id'
                    )
                  ).map((key) => {
                    const groupedSource = _.groupBy(
                      chatHistoryMessage.message_sources,
                      'source_document_id'
                    )[key]
                    return (
                      <div key={`message_source${key}`} className="w-full">
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap py-0.5">
                          {documents?.find((item) => item.uuid === key)?.title}
                        </div>
                        <div className="flex flex-wrap items-center gap-1 py-0.5">
                          <div>Pages:</div>
                          {groupedSource
                            .sort((a, b) => a.source - b.source)
                            .map((source, index) => (
                              <AIChatGroupedSource
                                key={`page_source_${source}`}
                                source={source}
                                index={index}
                                groupedSource={groupedSource}
                              />
                            ))}
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
        </div>
        {source !== 'user' &&
          (chatHistoryMessage.message_sources ?? [])?.length > 0 && (
            <AiChatSource
              chatMessage={chatHistoryMessage}
              documents={documents ?? []}
              message={chatHistoryMessage.message}
            />
          )}
        {source !== 'user' && user?.feature_flags?.conflicts ? (
          <AIChatConflict
            query={query}
            chatSessionMessage={chatHistoryMessage}
          />
        ) : null}
        {source !== 'user' && isMessageComplete() && (
          <div className="flex justify-between p-2 text-sm">
            <div>Rate this answer</div>
            <div className="flex w-1/2 space-x-1">
              <AIChatFeedbackButton
                message={chatHistoryMessage.message}
                type="Good"
                sessionUuid={sessionUuid}
                query={query}
              />
              <AIChatFeedbackButton
                message={chatHistoryMessage.message}
                type="Bad"
                sessionUuid={sessionUuid}
                query={query}
              />
            </div>
          </div>
        )}
        <AiChatQuestionPopover
          source={source}
          chatHistoryMessage={chatHistoryMessage}
        />
      </div>
    </div>
  )
}

export default AIChatMessage

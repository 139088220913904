import React, { useCallback, useMemo, useState } from 'react'
import {
  ProjectRisk,
  ProjectRiskComment,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import { ProjectDocumentMetadata } from '../../../../shared/interfaces/project/document/document.interface'
import RiskReviewSource from './risk-review-source'
import RiskReviewAnswerTextCitation from './risk-review-answer-text-citation'
import { InTextCitation } from '../../../../shared/interfaces/project/document/in-text-citation/in-text-citation.interface'
import RiskReviewCardComment from './risk-review-card-comment'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentProject,
  setModal,
} from '../../../../redux/application-slice'
import { POSTHOG } from '../../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import {
  Divider,
  HoverCard,
  Popover,
  Skeleton,
  Text,
  Tooltip,
} from '@mantine/core'
import clsx from 'clsx'
import { useTabWidth } from '../../../../hooks/use-tab-width'
import usePillData, { GeneratedPill } from './risk-review-pill'
// import { setSelectedCategoryFilter } from '../../../../redux/risks-search-slice'
// import { useCategoryIdToNameMapping } from '../risk-review-page/risk-review-page'

interface RiskReviewCardProps {
  projectRisk: ProjectRisk
  documents: ProjectDocumentMetadata[]
}

interface GroupedEndIndex {
  [index: number]: InTextCitation[]
}

const RiskReviewCard: React.FC<RiskReviewCardProps> = ({
  projectRisk,
  documents,
}) => {
  const [commentSelected, setCommentSelected] =
    useState<ProjectRiskComment | null>(null)
  const [sourcesExpanded, setSourcesExpanded] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const currentProject = useSelector(selectCurrentProject)
  const posthog = usePostHog()
  const dispatch = useDispatch()
  const isProcessing = projectRisk.processing_status === 0

  // 0 = No, 1=Low, 2=Medium, 3=High
  const [riskImportance, setRiskImportance] = useState<number | null>(
    projectRisk?.risk_ranking ?? 0
  )
  // 0 = No, 1=Approved, 2=Not Approved, 3=Needs Review
  const [riskPriority, setRiskPriority] = useState<number | null>(
    projectRisk?.status ?? 0
  )
  const BoldText: React.FC<{ text: string }> = ({ text }) => (
    <>
      {text.split(/(\*\*.*?\*\*)/).map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          return (
            <span key={index} className="font-semibold">
              {part.slice(2, -2)}
            </span>
          )
        }
        return part
      })}
    </>
  )

  const renderAnswerText = useMemo(() => {
    if (!projectRisk?.sources || !projectRisk?.answer_text) {
      return null
    }
    const sortedCitations = projectRisk.sources
      .filter(
        (citation) =>
          citation.start_index !== null && citation.end_index !== null
      )
      .sort((a, b) => (a?.start_index ?? 0) - (b?.start_index ?? 0))
    const answerTexts: JSX.Element[] = []
    let lastEndIndex = 0
    const groupedEndIndexes = sortedCitations.reduce<GroupedEndIndex>(
      (acc, citation) => {
        if (acc[citation.end_index ?? 0]) {
          acc[citation.end_index ?? 0].push(citation)
        } else {
          acc[citation.end_index ?? 0] = [citation]
        }
        return acc
      },
      {}
    )
    let total = 0
    for (const groupedIndex of Object.keys(groupedEndIndexes)) {
      const endIndex = parseInt(groupedIndex)
      const citations = groupedEndIndexes[endIndex]
      const textSlice = projectRisk?.answer_text?.slice(lastEndIndex, endIndex)
      answerTexts.push(
        <span key={textSlice}>
          <BoldText text={textSlice ?? ''} />
        </span>
      )
      for (const citation of citations) {
        answerTexts.push(
          <RiskReviewAnswerTextCitation
            key={`source_${citation.document_segment.quads?.[0].x1}${citation.document_segment.quads?.[0].y1}_${citation.start_index}_${citation.end_index}`}
            index={total}
            citation={citation}
            citations={citations}
          />
        )
        total += 1
      }
      lastEndIndex = endIndex
    }
    const finalTextSlice = projectRisk?.answer_text?.slice(lastEndIndex)
    answerTexts.push(
      <span key={finalTextSlice}>
        <BoldText text={finalTextSlice ?? ''} />
      </span>
    )
    return answerTexts
  }, [projectRisk])

  const onClickExpand = useCallback(() => {
    if (isProcessing) {
      return
    }
    setIsExpanded((s) => !s)
    posthog?.capture(POSTHOG.risk_review_risk_expanded, {
      project_uuid: currentProject?.uuid,
      risk_name: projectRisk?.risk_name,
      project_risk_uuid: projectRisk?.id,
    })
  }, [projectRisk, currentProject, posthog, isProcessing])

  const onClickSources = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setSourcesExpanded((s) => !s)
  }, [])

  const onPositiveFeedback = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_up, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: { positive: true, project_risk: projectRisk.id },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const onNegativeFeedback = useCallback(() => {
    posthog?.capture(POSTHOG.risk_review_item_feedback_thumbs_down, {
      project_uuid: currentProject?.uuid,
      project_risk_id: projectRisk.id,
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: { positive: false, project_risk: projectRisk.id },
      })
    )
  }, [dispatch, projectRisk, posthog, currentProject?.uuid])

  const generalCardClasses = clsx(
    'group flex w-full flex-grow rounded-lg flex-col items-center space-y-2 overflow-x-hidden cursor-pointer transition-colors',
    {
      'border bg-white shadow-sm active:border-amber-500 hover:border-amber-400 hover:bg-gray-100':
        !isProcessing,
      'border bg-white border-gray-300': isProcessing,
      '!bg-white': isExpanded,
    }
  )

  // const onCategoryClick = useCallback(
  //   (e) => {
  //     e.stopPropagation()
  //     if (projectRisk?.risk_category?.id) {
  //       dispatch(setSelectedCategoryFilter(projectRisk?.risk_category?.id))
  //     }
  //   },
  //   // eslint-disable-next-line
  //   [dispatch, projectRisk]
  // )

  // ! Components used before the card is expanded "Above The Fold"
  const RiskTitleRow = () => {
    // TODO - This was designed to map a category id to a name, but the backend is now sending the category name directly.
    // Ideally, we shouldn't send excess information and not use it, so either remove excess information that can be accessed by
    // using the allCategories fetch + an id. Right now we use the id+name mapping and ignore the name/sources sent by the BE.
    // const categoryNameFromId = useCategoryIdToNameMapping({
    //   riskCategoryId: projectRisk?.risk_category?.id ?? '',
    // })

    // Preprocess the text above the fold in case it has newlines.
    const textAboveTheFold = projectRisk?.risk_name
    const preprocessDefinitionText = (text: string | undefined): string[] => {
      if (!text) return []
      return text.split('\\n\\n')
    }
    const processedTextAboveTheFold = preprocessDefinitionText(textAboveTheFold)

    return (
      <div className="flex w-full min-w-0 flex-col items-start">
        {/* TODO is to action on whether this view is ever going to be useful, and to keep/remove this code accordingly */}
        {/* This previously showed the risk's category inside of the Card, and allowed users to set the Sections filter value by clicking it */}
        {/* {projectRisk?.risk_category !== null &&
        projectRisk?.processing_status === 1 &&
        (categoryNameFromId || projectRisk?.risk_category?.name) ? (
          <>
            <div className="flex w-full items-center justify-between">
              <Tooltip
                label={`Click to see all ${categoryNameFromId ?? ''} related risks`}
                position="right"
                offset={10}
              >
                <div
                  className="my-2 -ml-1 cursor-pointer"
                  onClick={onCategoryClick}
                >
                  <div className=" rounded-md border border-[#ffffff00] p-2 text-sm !text-gray-600 transition-colors hover:border-blue-500 hover:bg-blue-200 hover:!text-blue-700">
                    {categoryNameFromId !== ''
                      ? categoryNameFromId
                      : projectRisk?.risk_category?.name !== ''
                        ? projectRisk?.risk_category?.name
                        : 'Error Displaying Category'}
                  </div>
                </div>
              </Tooltip>
            </div>
            <Divider className="w-full text-gray-300" mb="sm" mt="4px" />
          </>
        ) : null} */}
        <h3
          className={clsx(
            'break-words pt-1 text-lg font-normal text-gray-900 group-hover:text-black',
            'mb-6 mt-3 max-w-full overflow-hidden text-ellipsis'
          )}
        >
          {processedTextAboveTheFold.map((text, index) => (
            <React.Fragment key={index}>
              {text}
              {index < processedTextAboveTheFold.length - 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </React.Fragment>
          ))}
        </h3>
      </div>
    )
  }

  const AttributeRow = ({ definitionText }: { definitionText?: string }) => {
    const pillData = usePillData(
      posthog,
      currentProject,
      projectRisk,
      setRiskPriority,
      setRiskImportance
    )

    const ApprovedPill = ({ clickable }: { clickable: boolean }) => (
      <GeneratedPill {...pillData.approved} clickable={clickable} />
    )
    const NotApprovedPill = ({ clickable }: { clickable: boolean }) => (
      <GeneratedPill {...pillData.notApproved} clickable={clickable} />
    )
    const NeedsReviewPill = ({ clickable }: { clickable: boolean }) => (
      <GeneratedPill {...pillData.needsReview} clickable={clickable} />
    )
    const HighPill = ({ clickable }: { clickable: boolean }) => (
      <GeneratedPill {...pillData.high} clickable={clickable} />
    )
    const MediumPill = ({ clickable }: { clickable: boolean }) => (
      <GeneratedPill {...pillData.medium} clickable={clickable} />
    )
    const LowPill = ({ clickable }: { clickable: boolean }) => (
      <GeneratedPill {...pillData.low} clickable={clickable} />
    )
    const CommentsPill = ({
      number,
      clickable,
    }: {
      number: number
      clickable: boolean
    }) => (
      <GeneratedPill
        {...pillData.comments}
        number={number}
        clickable={clickable}
      />
    )
    const NotSetPill = ({
      type,
      clickable,
    }: {
      type: string
      clickable: boolean
    }) => (
      <GeneratedPill
        {...pillData.notSet}
        title={`No ${type} Set`}
        clickable={clickable}
      />
    )
    const DefinitionsPill = ({ clickable }: { clickable: boolean }) => (
      <GeneratedPill {...pillData.definitions} clickable={clickable} />
    )

    const priorityPillsForTargetDisplay = {
      1: <ApprovedPill clickable={false} />,
      3: <NeedsReviewPill clickable={false} />,
      2: <NotApprovedPill clickable={false} />,
      0: <NotSetPill clickable={false} type="Priority" />,
    }

    const importancePillsForTargetDisplay = {
      3: <HighPill clickable={false} />,
      2: <MediumPill clickable={false} />,
      1: <LowPill clickable={false} />,
      0: <NotSetPill clickable={false} type="Importance" />,
    }

    const preprocessDefinitionText = (text: string | undefined): string[] => {
      if (!text) return []
      return text.split('\\n\\n')
    }

    const preProcessedDefinitionText = preprocessDefinitionText(definitionText)

    return projectRisk.processing_status === 1 ? (
      <div className="mb-4 flex flex-row items-start">
        <HoverCard
          withArrow
          arrowSize={16}
          width={140}
          shadow="md"
          openDelay={400}
        >
          <HoverCard.Target>
            <div data-testid="importance-selector-pill">
              {importancePillsForTargetDisplay[riskImportance ?? 0]}
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm" ta="center" fw="500">
              Importance
            </Text>
            <Divider my="8px" />
            <div className="flex flex-col items-center space-y-2">
              {riskImportance !== 3 ? <HighPill clickable={true} /> : null}
              {riskImportance !== 2 ? <MediumPill clickable={true} /> : null}
              {riskImportance !== 1 ? <LowPill clickable={true} /> : null}
            </div>
          </HoverCard.Dropdown>
        </HoverCard>

        <HoverCard
          withArrow
          arrowSize={16}
          width={180}
          shadow="md"
          openDelay={400}
        >
          <HoverCard.Target>
            <div data-testid="priority-selector-pill">
              {priorityPillsForTargetDisplay[riskPriority ?? 0]}
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm" ta="center" fw="500">
              Priority
            </Text>
            <Divider my="8px" />
            <div className="flex flex-col items-center space-y-2">
              {riskPriority !== 1 ? <ApprovedPill clickable={true} /> : null}
              {riskPriority !== 3 ? <NeedsReviewPill clickable={true} /> : null}
              {riskPriority !== 2 ? <NotApprovedPill clickable={true} /> : null}
            </div>
          </HoverCard.Dropdown>
        </HoverCard>

        {projectRisk?.comments?.length ? (
          <HoverCard
            withArrow
            arrowSize={16}
            width={400}
            shadow="md"
            openDelay={400}
          >
            <HoverCard.Target>
              <div data-testid="comments-selector-pill">
                <CommentsPill
                  number={projectRisk?.comments?.length}
                  clickable={false}
                />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm" ta="center" fw="500">
                All Comments
              </Text>
              <Divider my="8px" />
              <div className="max-h-[250px] overflow-y-auto p-2">
                {projectRisk.comments.map((comment, index) => (
                  <div key={index} className="mb-2 last:mb-0">
                    <div className="flex items-center justify-start">
                      <Text size="sm" fw="500" className="mb-1">
                        {`${comment.user?.first_name} ${comment.user?.last_name}`}
                      </Text>
                      <span className="ml-1 text-sm text-gray-400">
                        {'• '}
                        {new Date(comment?.date_created ?? '')
                          .toDateString()
                          .split(' ')
                          .slice(1)
                          .join(' ')}
                      </span>
                    </div>
                    <Text size="sm" className="pl-2">
                      {comment.comment}
                    </Text>

                    {index < (projectRisk?.comments?.length ?? 1) - 1 && (
                      <Divider my="8px" />
                    )}
                  </div>
                ))}
              </div>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : null}

        {definitionText ? (
          <HoverCard withArrow arrowSize={16} width={450} shadow="md">
            <HoverCard.Target>
              <div data-testid="definition-selector-pill">
                <DefinitionsPill clickable={false} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <div className="flex w-full items-center justify-center">
                <Text size="sm" fw="500">
                  Risk Definition
                </Text>
              </div>
              <Divider my="8px" />
              <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                <Text size="md" px="4px">
                  {preProcessedDefinitionText.map((text, index) => (
                    <React.Fragment key={index}>
                      {text}
                      {index < preProcessedDefinitionText.length - 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </Text>
              </div>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : null}
      </div>
    ) : (
      <div className="mb-3 flex flex-row items-start space-x-2">
        {[...Array(3)].map((_, i) => (
          <Skeleton
            key={i}
            height={28}
            width={100}
            styles={() => ({ root: { borderRadius: '16px' } })}
          />
        ))}
      </div>
    )
  }

  const AnalysisTitleText = ({ loading }: { loading?: boolean }) => {
    return (
      <div className="mb-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <div
              className={clsx('flex items-center', {
                'text-yellow-600': loading,
                'text-green-700': !loading,
              })}
            >
              {/* Presentation Icon from Phosphor Icons */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 256 256"
                className="mr-2"
              >
                <path d="M216,40H136V24a8,8,0,0,0-16,0V40H40A16,16,0,0,0,24,56V176a16,16,0,0,0,16,16H79.36L57.75,219a8,8,0,0,0,12.5,10l29.59-37h56.32l29.59,37a8,8,0,1,0,12.5-10l-21.61-27H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,136H40V56H216V176ZM104,120v24a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm32-16v40a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm32-16v56a8,8,0,0,1-16,0V88a8,8,0,0,1,16,0Z"></path>
              </svg>
              <span className="text-lg font-semibold">
                {loading ? 'No Analysis Found...' : 'Analysis'}
              </span>
            </div>

            <div className="flex flex-wrap gap-2 text-sm font-semibold">
              <button
                onClick={onPositiveFeedback}
                className="ml-4 flex h-7 w-7 flex-1 items-center justify-center rounded-lg bg-gray-50 text-green-600 hover:bg-green-200"
              >
                <HandThumbUpIcon className="h-5 w-5" />
              </button>
              <button
                onClick={onNegativeFeedback}
                className="flex h-7 w-7 flex-1 items-center justify-center rounded-lg bg-gray-50  text-red-600 hover:bg-red-200"
              >
                <HandThumbDownIcon className="h-5 w-5" />
              </button>
            </div>
          </div>

          <SourcesDropdown />
        </div>
      </div>
    )
  }

  const AnalysisRow = () => (
    <div className="mb-6">
      <AnalysisTitleText loading={!projectRisk?.answer_text} />
      <div className="whitespace-pre-wrap pr-28">
        {renderAnswerText ||
          "We couldn't find an analysis associated with this risk. This is likely an issue with Provision, and we apologize for the inconvenience. Please ensure all documents have finished processing. If the issue persists after waiting and refreshing the page, please contact support."}
      </div>
    </div>
  )

  const AboveTheFold = () => {
    return (
      <div
        className={clsx(
          'grid w-full grid-cols-[1fr_57px] items-center overflow-hidden px-1 py-2 pl-9',
          {
            'animate-pulse bg-white': isProcessing,
          }
        )}
      >
        <button
          className="mt-1 flex min-w-0 flex-grow items-center space-y-1 text-left"
          onClick={onClickExpand}
          disabled={isProcessing}
        >
          <div className="flex min-w-0 flex-grow flex-col">
            <RiskTitleRow />
            <AttributeRow definitionText={projectRisk?.risk_description} />
          </div>
        </button>
        {isProcessing ? (
          <Tooltip
            label={
              <>
                This risk is currently being analyzed. <br />
                Please check back soon.
              </>
            }
            multiline
            w="260px"
            position="left"
          >
            <ClockIcon className="h-8 w-8 text-gray-600" />
          </Tooltip>
        ) : (
          <Tooltip
            label={isExpanded ? 'Hide Details' : 'View Risk Analysis Details'}
            position="right"
            openDelay={400}
          >
            <div
              className="relative ml-2 mr-5 cursor-pointer p-1"
              onClick={onClickExpand}
            >
              <div className="absolute inset-0 rounded-full border border-slate-300 bg-slate-100 transition-colors group-hover:border-yellow-500 group-hover:bg-yellow-400"></div>
              {isExpanded ? (
                <ChevronUpIcon className="relative h-5 w-5 flex-shrink-0 text-gray-900 group-hover:text-black" />
              ) : (
                <ChevronDownIcon className="relative h-5 w-5 flex-shrink-0 text-gray-900 group-hover:text-black" />
              )}
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  // ! Components used after the card is expanded "Beneath The Fold"
  const SourcesDropdown = () => {
    const { tabWidth } = useTabWidth()

    const cardHoverClasses = clsx('flex items-center justify-start', {
      'cursor-pointer': projectRisk?.sources && projectRisk?.sources.length > 0,
      '!cursor-default':
        !projectRisk?.sources || projectRisk?.sources.length <= 0,
    })

    return (
      <Popover position="bottom-end" offset={12} shadow="lg">
        <Popover.Target>
          <div
            onClick={
              projectRisk?.sources && projectRisk?.sources.length > 0
                ? onClickSources
                : undefined
            }
            className={cardHoverClasses}
          >
            {/* Bookmark Icon from Phosphor Icons */}
            <div
              className={clsx({
                'text-gray-400':
                  !projectRisk?.sources || projectRisk?.sources.length <= 0,
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 256 256"
                className="mr-1"
              >
                <path d="M184,32H72A16,16,0,0,0,56,48V224a8,8,0,0,0,12.24,6.78L128,193.43l59.77,37.35A8,8,0,0,0,200,224V48A16,16,0,0,0,184,32Zm0,177.57-51.77-32.35a8,8,0,0,0-8.48,0L72,209.57V48H184Z"></path>
              </svg>
            </div>
            <span
              className={clsx('text-md font-semibold', {
                'text-gray-400':
                  projectRisk?.sources && projectRisk?.sources.length <= 0,
              })}
            >
              {projectRisk?.sources && projectRisk?.sources.length > 0
                ? 'Sources'
                : 'No Sources'}
            </span>
            {projectRisk?.sources &&
              projectRisk?.sources.length > 0 &&
              (sourcesExpanded ? (
                <ChevronUpIcon className="ml-1 h-4 w-4" />
              ) : (
                <ChevronDownIcon className="ml-1 h-4 w-4" />
              ))}
          </div>
        </Popover.Target>
        <Popover.Dropdown
          className={`z-50 mt-3 rounded-xl border border-gray-200 bg-white p-2 hover:border-yellow-500`}
        >
          <div style={{ width: tabWidth - 280 }}>
            <div className="max-h-[450px] overflow-y-auto">
              {projectRisk?.sources?.map((citation, index) => (
                <div key={`source_${index}`} className="mb-2 last:mb-0">
                  <RiskReviewSource
                    isExpanded={isExpanded}
                    sourceIndex={index}
                    documents={documents}
                    citation={citation}
                    citations={projectRisk?.sources ?? []}
                  />
                </div>
              ))}
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>
    )
  }

  const BeneathTheFold = () => {
    return (
      <>
        <div className="text-md w-full cursor-default space-y-2 overflow-hidden bg-white">
          <Divider color="#dadada" mt="-0px" size="sm" w="100%" />
          <div className="px-8 pl-12 pt-4">
            <AnalysisRow />
          </div>
          <div className="flex items-center justify-start px-8 pb-6 pl-12">
            <ExclamationTriangleIcon className="mr-2 h-4 w-4 text-gray-500" />
            <Text c="dimmed" size="sm" className="mb-2">
              Provision can make mistakes. Consider checking important
              information.
            </Text>
          </div>
          <Divider color="#dadada" size="sm" w="100%" />
        </div>

        <div className="w-full p-4 pl-12 pt-0">
          <RiskReviewCardComment
            projectRisk={projectRisk}
            commentSelected={commentSelected}
            setCommentSelected={setCommentSelected}
          />
        </div>
      </>
    )
  }

  return (
    <div className={generalCardClasses}>
      <AboveTheFold />
      {isExpanded ? <BeneathTheFold /> : null}
    </div>
  )
}

export default RiskReviewCard

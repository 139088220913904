import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  setCurrentDocument,
  setModal,
  setProjectSidebarOpen,
} from '../../redux/application-slice'
import {
  useCreateProjectMutation,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { ProjectRegion } from '../../shared/interfaces/project/project.interface'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'

interface NewProjectModalProps {
  open: boolean
}

function NewProjectModal({ open }: NewProjectModalProps) {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const [submitting, setSubmitting] = useState(false)
  const [isDefinitionsChecked, setIsDefinitionsChecked] = useState(false)
  const [isShareProjectChecked, setIsSharedProjectChecked] = useState(false)
  const { register, handleSubmit, reset } = useForm()
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const navigate = useNavigate()
  const [createProject] = useCreateProjectMutation()
  const [region, setRegion] = useState<ProjectRegion>('GLOBAL')
  const { data: user } = useGetUserProfileQuery(undefined)

  const onClose = useCallback(() => {
    dispatch(setModal(null))
    setIsDefinitionsChecked(false)
    setIsSharedProjectChecked(false)
    setSubmitting(false)
    setRegion('GLOBAL')
    reset()
  }, [dispatch, reset])

  const onSubmit = useCallback(
    async (data) => {
      if (submitting) return

      setSubmitting(true)
      try {
        const project = await createProject({
          title: data.project_name,
          share_definitions: isDefinitionsChecked,
          is_shared: isShareProjectChecked,
          region,
        }).unwrap()
        setTimeout(() => {
          documentViewer?.closeDocument()
        }, 500)
        dispatch(setCurrentDocument(null))
        posthog?.capture(POSTHOG.project_created, {
          project_uuid: project.uuid,
        })
        dispatch(setProjectSidebarOpen(false))
        navigate(`${project?.uuid}/documents`)
      } catch (error) {
        toast.error('Something went wrong. Please try again.')
        setSubmitting(false)
      }
      onClose()
    },
    [
      createProject,
      dispatch,
      documentViewer,
      isDefinitionsChecked,
      navigate,
      posthog,
      submitting,
      onClose,
      isShareProjectChecked,
      region,
    ]
  )

  const onChangeDefinitionChecked = useCallback(() => {
    setIsDefinitionsChecked((d) => !d)
  }, [])

  const onChangeProjectSharedChecked = useCallback(() => {
    setIsSharedProjectChecked((d) => !d)
  }, [])

  const onSelectRegion = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRegion(e.target.value as ProjectRegion)
    },
    []
  )

  const definitionContent = useMemo(() => {
    return (
      <div className="relative mt-5 flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="not-legal-checkbox"
            aria-describedby="not-legal-description"
            name="not-legal"
            type="checkbox"
            checked={isDefinitionsChecked}
            onChange={onChangeDefinitionChecked}
            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label
            htmlFor="not-legal-checkbox"
            className="cursor-pointer font-medium text-gray-900"
          >
            <div className="text-s">Share Definitions between documents</div>
            <div className="text-xs">
              Definitions will be shared between all documents in this project.
              Only enable this if you are reviewing a multi-schedule contract.
            </div>
          </label>
        </div>
      </div>
    )
  }, [isDefinitionsChecked, onChangeDefinitionChecked])

  const sharedProjectContent = useMemo(() => {
    return (
      <div className="relative mt-5 flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="shared-project-checkbox"
            aria-describedby="share-project-description"
            name="share-project"
            type="checkbox"
            checked={isShareProjectChecked}
            onChange={onChangeProjectSharedChecked}
            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label
            htmlFor="shared-project-checkbox"
            className="cursor-pointer font-medium text-gray-900"
          >
            <div className="text-s">Share Project</div>
            <div className="text-xs">
              Project will be shared with all users in your organization.
            </div>
          </label>
        </div>
      </div>
    )
  }, [isShareProjectChecked, onChangeProjectSharedChecked])

  const projectRegionContent = useMemo(() => {
    return (
      <div
        data-cy={`project-region-content`}
        className="relative mt-5 flex flex-col items-start"
      >
        <label
          htmlFor="project-region"
          className="flex items-center space-x-1 text-sm font-medium text-gray-700"
        >
          <span>Project Region</span>
          <InformationCircleIcon
            data-tooltip-id="project-region-tooltip"
            data-tooltip-content={
              'Select the region for the project.\nThis value determines where the project data is stored.\nThis value cannot be changed once set. If you are unsure, select North America.'
            }
            className="h-5 w-5 text-gray-700"
          />
        </label>
        <select
          name="project-region"
          id="project-region"
          value={region}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
          onChange={onSelectRegion}
        >
          <option value="GLOBAL">North America</option>
          <option value="CANADA">Canada</option>
        </select>
      </div>
    )
  }, [onSelectRegion, region])

  const dialogContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Project name
              </label>
              <input
                {...register('project_name')}
                tabIndex={0}
                type="text"
                required={true}
                name="project_name"
                id="project_name"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
            {user?.feature_flags?.project_regions === true
              ? projectRegionContent
              : null}
            {definitionContent}
            {sharedProjectContent}
            <div className="mt-5 sm:mt-6">
              <button
                data-cy="create-project-button"
                type="submit"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </Dialog.Panel>
    )
  }, [
    handleSubmit,
    onSubmit,
    register,
    projectRegionContent,
    definitionContent,
    sharedProjectContent,
    user,
  ])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
        <Tooltip
          id={'project-region-tooltip'}
          style={{ zIndex: 100, fontSize: '0.75rem' }}
          className="whitespace-pre-wrap text-xs"
        />
      </Dialog>
    </Transition.Root>
  )
}

export default NewProjectModal

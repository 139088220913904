import { Popover } from '@headlessui/react'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useState } from 'react'
import { usePopper } from 'react-popper'
import {
  ProjectRisk,
  ProjectRiskComment,
} from '../../../shared/interfaces/project/risk/risk-inteface'
import { useDeleteProjectRiskCommentMutation } from '../../../redux/api/project-risk-api-slice'
import { selectRiskWorkflowFilterState } from '../../../redux/workflow-slice'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mantine/core'

interface ProjectRiskPopoverProps {
  projectRiskComment: ProjectRiskComment
  projectRisk: ProjectRisk
  projectId?: string
  setCommentSelected: (comment: ProjectRiskComment) => void
  onCommentCommit?: () => void
  //   onApplyChecklistTemplate: (c: ChecklistTemplate) => void;
}

const ProjectRiskPopover: React.FC<ProjectRiskPopoverProps> = ({
  projectRisk,
  projectRiskComment,
  projectId,
  setCommentSelected,
  onCommentCommit,
}) => {
  //   const currentProject = useSelector(selectCurrentProject) as Project;
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)

  const [deleteProjectRiskComment] = useDeleteProjectRiskCommentMutation()

  const onDeleteProjectRiskComment = useCallback(async () => {
    referenceElement?.click()
    await deleteProjectRiskComment({
      id: projectRiskComment.id,
      parent: projectRisk,
      project_uuid: projectId,
      project_risk_id: projectRisk.id,
      sort: riskWorkflowFilterState?.sort,
    })
    onCommentCommit?.()
  }, [
    referenceElement,
    deleteProjectRiskComment,
    projectRiskComment.id,
    projectRisk,
    projectId,
    riskWorkflowFilterState?.sort,
    onCommentCommit,
  ])

  const onClickEditComment = useCallback(() => {
    setCommentSelected(projectRiskComment)
    referenceElement?.click()
  }, [projectRiskComment, setCommentSelected, referenceElement])

  return (
    <Popover>
      <Popover.Button
        data-playwright={`project-risk-comment-popover-${projectRiskComment.id}`}
        className="rounded-lg px-2 py-2 hover:bg-gray-200"
        tabIndex={-1}
        ref={setReferenceElement}
      >
        <Tooltip label="Edit or Delete Comment" position="left" offset={12}>
          <div className="flex items-center justify-center">
            <PencilIcon className="mr-2 h-5 w-5" />
            <TrashIcon className="h-5 w-5" />
          </div>
        </Tooltip>
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50 flex flex-col rounded border bg-white text-sm shadow"
      >
        <button
          className="w-36 cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
          onClick={onClickEditComment}
        >
          Edit
        </button>
        <button
          className="w-36 cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
          onClick={onDeleteProjectRiskComment}
        >
          Delete
        </button>
      </Popover.Panel>
    </Popover>
  )
}

export default ProjectRiskPopover

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useCreateRiskExportMutation,
  useGetDocumentsListByProjectQuery,
} from '../../../../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import RiskReviewCard from '../risk-review-card/risk-review-card'
import {
  ProjectRisk,
  RiskStatusIntToString,
} from '../../../../shared/interfaces/project/risk/risk-inteface'
import { useGetProjectRiskQuery } from '../../../../redux/api/project-risk-api-slice'

import { POSTHOG } from '../../../../utils/posthog-constants'
import {
  Skeleton,
  Tooltip as MantineTooltip,
  Loader,
  Popover,
} from '@mantine/core'
import { WorkflowFilter } from '../../workflow-components/filter-display'
import { useSearchParams } from 'react-router-dom'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentArrowDownIcon,
  ExclamationCircleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  selectRiskWorkflowFilterState,
  setRiskWorkflowFilterState,
} from '../../../../redux/workflow-slice'
import { Divider, Paper, Text } from '@mantine/core'

import { MenuPopover } from '../../../layout/menu-popover'
import { usePostHog } from 'posthog-js/react'
import { toast } from 'react-toastify'
import { groupBy } from 'lodash'

import {
  selectRiskListSearchQuery,
  selectSelectedCategoryForRiskList,
  selectSelectedDocuments,
  setRiskListSearchQuery,
  setSelectedCategoryFilter,
  setSelectedDocuments,
} from '../../../../redux/risks-search-slice'
import { useTabWidth } from '../../../../hooks/use-tab-width'
import clsx from 'clsx'
import { setSelectedRiskList } from '../../../../redux/risks-search-slice'
import { useElementWidth } from '../../../../hooks/use-element-width'
import { Tooltip } from 'react-tooltip'
import { useGetRiskCategoriesQuery } from '../../../../redux/api/qa-api-slice'
import { SearchFiltersCard } from './search-filters-card'
import { ActiveDot } from '../../../workflow-sidebar/risk-review-header'
import RiskReviewSource from '../risk-review-card/risk-review-source'

export const useCategoryIdToNameMapping = ({
  riskCategoryId,
}: {
  riskCategoryId: string
}): string => {
  const currentProject = useSelector(selectCurrentProject)
  // TODO put this payload in Redux, so we dont have to fetch it every time a category is displayed (ouch)
  const { data: riskCategories } = useGetRiskCategoriesQuery(
    currentProject?.uuid ?? ''
  )

  if (!riskCategories) {
    return ''
  }

  const topLevelMapping = riskCategories.reduce(
    (acc, category) => {
      if (category.id && category.name) {
        acc[category.id] = category.name
      }
      return acc
    },
    {} as Record<string, string>
  )
  return topLevelMapping[riskCategoryId]
}

const RiskReviewPage: React.FC = () => {
  const currentProject = useSelector(selectCurrentProject)
  const riskListSearchQuery = useSelector(selectRiskListSearchQuery)
  // eslint-disable-next-line
  const selectedDocuments = useSelector(selectSelectedDocuments)
  const dispatch = useDispatch()

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const [filter, setFilter] = useState<WorkflowFilter>({})

  const [riskPollingInterval, setRiskPollingInterval] = useState<
    number | undefined
  >(undefined)
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)
  const { currentData: projectRisks } = useGetProjectRiskQuery(
    riskWorkflowFilterState?.projectUUID
      ? {
          projectUUID: riskWorkflowFilterState?.projectUUID,
          status: riskWorkflowFilterState?.status,
          sort: riskWorkflowFilterState?.sort,
        }
      : skipToken,
    {
      pollingInterval: riskPollingInterval,
    }
  )
  useEffect(() => {
    dispatch(setRiskWorkflowFilterState({ projectUUID: currentProject?.uuid }))
  }, [currentProject?.uuid, dispatch])

  useEffect(() => {
    if (!projectRisks || projectRisks?.some((r) => r.processing_status === 0)) {
      setRiskPollingInterval(5000)
    } else {
      setRiskPollingInterval(undefined)
    }
  }, [projectRisks])
  const posthog = usePostHog()

  // eslint-disable-next-line
  const selectedRiskList = useSelector(
    (state: any) => state.riskListState.selectedRiskList
  )

  const [createRiskExport] = useCreateRiskExportMutation()
  const [searchParams] = useSearchParams()
  const commentUUID = searchParams.get('comment')

  // eslint-disable-next-line
  const { data: riskCategories } = useGetRiskCategoriesQuery(
    currentProject?.uuid ?? ''
  )

  const onExcelExport = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    toast.success('Exporting Risks as Excel...')
    createRiskExport({
      projectId: currentProject?.uuid,
      type: 'xls',
      documentUuids: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      riskList: selectedRiskList?.id,
      riskSort: riskWorkflowFilterState?.sort?.toString(),
      searchQuery: riskListSearchQuery,
    })
      .unwrap()
      .then((response) => {
        window.open(response.export_url, '_blank')
      })
    posthog?.capture(POSTHOG.export_risk_excel, {
      project_uuid: currentProject?.uuid,
    })
  }, [
    createRiskExport,
    currentProject?.uuid,
    posthog,
    riskListSearchQuery,
    selectedDocuments,
    selectedRiskList?.id,
    riskWorkflowFilterState?.sort,
  ])

  const onExcelPDF = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    toast.success('Exporting Risks as PDF...')
    createRiskExport({
      projectId: currentProject?.uuid,
      type: 'pdf',
      documentUuids: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      riskList: selectedRiskList?.id,
      riskSort: riskWorkflowFilterState?.sort?.toString(),
      searchQuery: riskListSearchQuery,
    })
      .unwrap()
      .then((response) => {
        window.open(response.export_url, '_blank')
      })
    posthog?.capture(POSTHOG.export_risk_pdf, {
      project_uuid: currentProject?.uuid,
    })
  }, [
    createRiskExport,
    currentProject?.uuid,
    posthog,
    riskListSearchQuery,
    selectedDocuments,
    selectedRiskList?.id,
    riskWorkflowFilterState?.sort,
  ])

  const SHARE_CARD_POPOVER_OPTIONS = [
    {
      label: 'Export to Excel/CSV',
      leftSectionIcon: (
        <DocumentArrowDownIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      disabled: (projectRisks ?? []).length === 0,
      onClickMethod: onExcelExport,
    },

    {
      label: 'Export to PDF',
      leftSectionIcon: (
        <DocumentArrowDownIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
      ),
      rightSectionText: '',
      disabled: (projectRisks ?? []).length === 0,
      onClickMethod: onExcelPDF,
    },
    {
      label: 'Close Menu',
      leftSectionIcon: (
        <XMarkIcon className="ml-0.5 h-5 w-5 cursor-pointer stroke-gray-600 group-hover:text-black" />
      ),
      rightSectionText: '',
    },
  ]

  const SECTIONS_CARD_POPOVER_OPTIONS = useMemo(() => {
    const categoryOptions =
      riskCategories?.map((category) => ({
        label: category.name,
        rightSectionText: '',
        onClickMethod: () => {
          dispatch(setSelectedCategoryFilter(category.id?.toString() ?? null))
        },
      })) || []

    return [
      ...categoryOptions,
      {
        label: 'Close Menu',
        leftSectionIcon: (
          <XMarkIcon className="h-5 w-5 text-gray-700 group-hover:text-black" />
        ),
        rightSectionText: '',
      },
    ]
  }, [riskCategories, dispatch])

  useEffect(() => {
    if (!commentUUID) {
      return
    }
    setFilter((f) => {
      return { ...f, comment_uuid: commentUUID }
    })
  }, [commentUUID])

  const filteredRisks = useMemo(() => {
    let risks: ProjectRisk[] = []
    if (!projectRisks) {
      return []
    }
    if (!selectedDocuments?.length) {
      risks = projectRisks
    } else {
      risks = projectRisks?.reduce<ProjectRisk[]>((acc, cur) => {
        const doc_uuids = cur.sources?.map((c) => c.document_segment.document)
        const relevantDocUUIDs = selectedDocuments
          ?.filter((d) => doc_uuids?.includes(d.uuid))
          .map((d) => d.uuid)
        if (relevantDocUUIDs?.length) {
          const filteredSources = cur.sources?.filter((s) =>
            relevantDocUUIDs.includes(
              s.document_segment.document?.toString() ?? ''
            )
          )
          const filteredRisk = {
            ...cur,
            sources: filteredSources,
          }
          return [...acc, filteredRisk]
        }
        return acc
      }, [])
    }
    return risks
  }, [projectRisks, selectedDocuments])

  const selectedCategory = useSelector(selectSelectedCategoryForRiskList)

  const risksToDisplay = useMemo(() => {
    if (filter?.comment_uuid) {
      return filteredRisks?.filter((r) => {
        return r?.comments?.some((c) => c?.id === filter.comment_uuid)
      })
    }
    if (!selectedRiskList && !riskListSearchQuery && !selectedCategory) {
      return filteredRisks
    }
    const risks = selectedRiskList
      ? filteredRisks?.filter((r) => {
          const riskListIds = r.risk_lists?.map((rl) => rl.id)
          if (!riskListIds) {
            return false
          }
          return riskListIds.includes(selectedRiskList?.id)
        })
      : filteredRisks
    if (selectedCategory !== '' && selectedCategory !== null) {
      return risks.filter((r) => {
        return r?.risk_category?.id === selectedCategory
      })
    }
    if (riskListSearchQuery) {
      return risks.filter((r) => {
        return r?.risk_name
          ?.toLowerCase()
          .includes(riskListSearchQuery.toLowerCase())
      })
    }

    return risks
  }, [
    selectedRiskList,
    riskListSearchQuery,
    filter,
    filteredRisks,
    selectedCategory,
  ])

  const ShareCardPopover = () => {
    const nothingToExport =
      riskListSearchQuery !== '' && risksToDisplay.length === 0
    return (
      <MenuPopover
        disabled={nothingToExport}
        type="Settings"
        isSearchable={false}
        options={SHARE_CARD_POPOVER_OPTIONS}
        width={300}
        topLabel="Export Risks"
      >
        <div className="cursor-pointer">
          <Paper
            className={clsx(
              'group !rounded-lg border p-2.5 transition-colors',
              {
                'hover:!border-blue-400 hover:!bg-blue-100 hover:bg-gray-50 active:border-blue-500 active:!bg-white':
                  !nothingToExport,
              }
            )}
            styles={() => ({
              root: {
                backgroundColor: '#e5e7eb',
                cursor: nothingToExport ? 'default' : 'pointer',
              },
            })}
          >
            <div className="flex items-center justify-center">
              <div
                className={clsx('flex text-gray-500', {
                  'group-hover:text-blue-600': !nothingToExport,
                })}
              >
                <DocumentArrowDownIcon className="h-5 w-5" />
                <Text p="0" size="sm" fw={400} ml="4px" c="currentColor">
                  Export
                </Text>
              </div>
            </div>
          </Paper>
        </div>
      </MenuPopover>
    )
  }

  const SectionsCardPopover = () => (
    <MenuPopover
      type="Sections"
      options={SECTIONS_CARD_POPOVER_OPTIONS}
      width={500}
      topLabel="Risks by Sections"
    >
      <MantineTooltip
        w={316}
        position="bottom"
        multiline
        openDelay={400}
        label="Sections are pre-defined categories for risks. Click to view all risks in a specific section."
      >
        <Paper
          className="group ml-2 cursor-pointer !rounded-lg border p-2.5 transition-colors hover:!border-blue-400 hover:!bg-blue-100 hover:bg-gray-50 active:border-blue-500 active:!bg-white"
          styles={() => ({
            root: {
              backgroundColor: '#e5e7eb',
              cursor: 'pointer',
            },
          })}
        >
          <div className="flex items-center justify-center">
            <div className="flex flex-col">
              <div className="flex text-gray-500 group-hover:text-blue-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M184,72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V88A16,16,0,0,0,184,72Zm0,128H40V88H184V200ZM232,56V176a8,8,0,0,1-16,0V56H64a8,8,0,0,1,0-16H216A16,16,0,0,1,232,56Z"></path>
                </svg>
                <Text p="0" size="sm" fw={400} ml="4px" c="currentColor">
                  Sections
                </Text>
              </div>
            </div>
            <ActiveDot active={selectedCategory !== null} />
          </div>
        </Paper>
      </MantineTooltip>
    </MenuPopover>
  )

  const NumberOfRisksPopover = () => (
    <Paper
      className="group ml-2 !cursor-default whitespace-nowrap !rounded-lg border p-2.5"
      styles={() => ({
        root: {
          backgroundColor: '#e5e7eb',
          cursor: 'pointer',
        },
      })}
    >
      <div className="flex items-center justify-center">
        <div className="flex flex-col">
          <div className="flex text-gray-600">
            <Text p="0" size="sm" fw={400} ml="4px" c="currentColor">
              {risksToDisplay.length === 0
                ? 'No risks found'
                : risksToDisplay.length === projectRisks?.length
                  ? `Viewing ${risksToDisplay.length} Risks`
                  : `Viewing ${risksToDisplay.length} of ${projectRisks?.length} Risks`}
            </Text>
          </div>
        </div>
      </div>
    </Paper>
  )

  // This is an exact copy of the handleResetSortAndFilter in risk-review-card.tsx
  const handleResetSortAndFilter = useCallback(() => {
    const { status, sort, ...rest } = riskWorkflowFilterState
    dispatch(
      setRiskWorkflowFilterState({
        status: undefined,
        sort: '',
        ...rest,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  // This is an exact copy of the handleResetAllFilters in risk-review-card.tsx
  const handleResetAllFilters = () => {
    dispatch(setRiskListSearchQuery(''))
    dispatch(setSelectedRiskList(null))
    dispatch(setSelectedDocuments([]))
    dispatch(setSelectedCategoryFilter(null))
    handleResetSortAndFilter()
  }

  const NoProjectRisks = () => (
    <div className=" h-[calc(100%-104px)] p-4 pr-5 pt-3">
      <RiskListHeader />
      <Divider color="#d1d1d1" mt="12px" mb="16px" size="xs" w="100%" />
      <div className="flex h-full flex-col items-center justify-center px-16 text-center text-xl text-gray-700">
        <div className="flex items-center justify-center text-xl">
          <ExclamationCircleIcon className="mr-2 h-8 w-8" />
          <div>No risks found</div>
        </div>
        <Divider color="#d1d1d1" my="16px" size="xs" w="45%" />
        <Paper
          onClick={handleResetAllFilters}
          style={{ width: '180px' }}
          className="group cursor-pointer !rounded-lg border !border-red-400 px-2 py-1.5 transition-all duration-300 ease-in-out hover:!bg-red-100"
        >
          <div className="flex items-center justify-center">
            <div className="relative flex h-5 w-5 flex-shrink-0 items-center">
              <TrashIcon className="text-red-500" />
            </div>
            <div className="ml-1 flex items-center justify-center font-[600]">
              <span className="text-[16px] font-normal text-red-500">
                Clear all Filters
              </span>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  )

  const ProcessingDocumentsNotice = () => (
    <div className=" h-full p-4 pr-5 pt-3">
      <div className="flex h-full flex-col items-center justify-center px-16 text-center text-xl text-black">
        <div className="flex items-center justify-center text-xl">
          <Loader size="sm" className="mr-4 h-6 w-6" color="yellow" />
          <div>Risks are currently processing</div>
        </div>
      </div>
    </div>
  )

  const ProjectRisksLoading = () => (
    <div className="mt-[70px] flex h-full w-full flex-col items-center justify-center p-4 pr-6">
      <div className="h-full w-full space-y-3 ">
        {Array.from({ length: 7 }).map((_, index) => (
          <Skeleton
            key={index}
            h="112.55px"
            w="100%"
            radius="md"
            className="!border !border-gray-300"
          />
        ))}
      </div>
    </div>
  )

  const currentSort = useSelector(selectRiskWorkflowFilterState)?.sort
  const categoryName = useCategoryIdToNameMapping({
    riskCategoryId: selectedCategory ?? '',
  })

  const FiltersDisplayStringFormatter = useMemo(() => {
    const parts: string[] = []

    const joinedSelectedDocuments = selectedDocuments
      ?.map((doc) => doc.title)
      .join(', and ')

    if (riskListSearchQuery) {
      parts.push(`Searching for "${riskListSearchQuery}"`)
    }

    if ((selectedDocuments ?? []).length > 0) {
      if (parts.length === 0) {
        parts.push(`In "${joinedSelectedDocuments}"`)
      } else {
        parts.push(`in "${joinedSelectedDocuments}"`)
      }
    }

    if (currentSort) {
      if (parts.length === 0) {
        parts.push(`Sorted by "${currentSort}"`)
      } else {
        parts.push(`sorted by "${currentSort}"`)
      }
    }

    if (selectedRiskList !== null) {
      if (parts.length === 0) {
        parts.push(`Filtered by "${selectedRiskList.name}"`)
      } else {
        parts.push(`filtered by "${selectedRiskList.name}"`)
      }
    }

    if (riskWorkflowFilterState?.status) {
      if (parts.length === 0) {
        parts.push(
          `Filtered by "${RiskStatusIntToString(
            riskWorkflowFilterState?.status ?? 0
          )}"`
        )
      } else {
        parts.push(
          `filtered by "${RiskStatusIntToString(riskWorkflowFilterState?.status ?? 0)}"`
        )
      }
    }

    if (selectedCategory) {
      if (parts.length === 0) {
        parts.push(`Filtered by section "${categoryName}"`)
      } else {
        parts.push(`filtered by section "${categoryName}"`)
      }
    }

    return (parts ?? []).join(', ')
  }, [
    riskListSearchQuery,
    currentSort,
    selectedRiskList,
    selectedDocuments,
    riskWorkflowFilterState,
    selectedCategory,
    categoryName,
  ])

  const RiskListHeader = () => {
    const { tabWidth } = useTabWidth()

    const [shareCardRef, shareCardWidth] = useElementWidth()
    const [filtersCardRef, filtersCardWidth] = useElementWidth()

    const cardsTooWideForPanelWidth =
      shareCardWidth + filtersCardWidth + 24 > tabWidth

    return (
      <div
        className={clsx(
          '-ml-2 grid',
          cardsTooWideForPanelWidth
            ? FiltersDisplayStringFormatter !== ''
              ? 'grid-cols-[1fr] grid-rows-[repeat(2,_1fr)] gap-x-[0px] gap-y-[0px]'
              : 'grid-cols-[1fr] grid-rows-[1fr] gap-x-[0px] gap-y-[0px]'
            : 'grid-cols-[320px_1fr] grid-rows-[1fr] gap-x-[0px] gap-y-[0px]'
        )}
      >
        <div className="flex items-center justify-start [grid-area:1_/_1_/_2_/_2]">
          <div ref={shareCardRef} className="flex items-center">
            <NumberOfRisksPopover />
            <ShareCardPopover />
            <SectionsCardPopover />
          </div>
        </div>

        {FiltersDisplayStringFormatter !== '' ? (
          <div
            className={clsx(
              'flex items-center',
              cardsTooWideForPanelWidth
                ? 'justify-start pl-2 pt-0.5 [grid-area:2_/_1_/_3_/_2]'
                : 'justify-end [grid-area:1_/_2_/_2_/_3]'
            )}
          >
            <div ref={filtersCardRef}>
              <SearchFiltersCard
                defaultText={FiltersDisplayStringFormatter}
                panelWidth={tabWidth}
              />
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  const groupedRisks = useMemo(() => {
    const grouped = groupBy(
      risksToDisplay,
      (risk) => risk.risk_category?.name || 'No Section'
    )

    // Sort categories alphabetically
    const sortedCategories = Object.keys(grouped).sort((a, b) =>
      a.localeCompare(b)
    )

    // Sort risks within each category by priority
    const sortedGrouped = sortedCategories.reduce((acc, category) => {
      acc[category] = grouped[category].sort(
        (a, b) => (b.status || 0) - (a.status || 0)
      )
      return acc
    }, {})

    return sortedGrouped
  }, [risksToDisplay])

  const CategorySourcesDropdown = ({
    categorySources,
  }: {
    categorySources: any[] // TODO: Define proper type
  }) => {
    const { tabWidth } = useTabWidth()
    const [sourcesExpanded, setSourcesExpanded] = useState(false)

    const onClickSources = () => {
      setSourcesExpanded(!sourcesExpanded)
    }

    return (
      <Popover position="bottom-end" offset={12} shadow="lg">
        <Popover.Target>
          <div
            onClick={onClickSources}
            className="mr-2 flex cursor-pointer items-center justify-start"
          >
            <span className="text-md font-normal">Section Sources</span>
            {sourcesExpanded ? (
              <ChevronUpIcon className="ml-1 h-4 w-4" />
            ) : (
              <ChevronDownIcon className="ml-1 h-4 w-4" />
            )}
          </div>
        </Popover.Target>
        <Popover.Dropdown
          className={`z-50 mt-3 rounded-xl border border-gray-200 bg-white p-2 hover:border-yellow-500`}
        >
          <div style={{ width: tabWidth - 280 }}>
            <div className="max-h-[450px] overflow-y-auto">
              {categorySources.map((source, index) => (
                <div key={`source_${index}`} className="mb-2 last:mb-0">
                  <RiskReviewSource
                    isExpanded={sourcesExpanded}
                    sourceIndex={index}
                    documents={selectedDocuments ?? []}
                    citation={source}
                    citations={categorySources}
                  />
                </div>
              ))}
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>
    )
  }

  const MemoizedProjectRisks = useMemo(() => {
    const ProjectRisks = () => {
      const isCategoryValid = (category: string | null) =>
        category !== '' && category !== null && category !== 'No Section'

      return (
        <div className="mr-2 w-full flex-auto space-y-2 overflow-auto p-4 pr-5 pt-3">
          <RiskListHeader />
          <Divider color="#d1d1d1" mt="10px" mb="12px" size="xs" w="100%" />

          {/* Risk Card List */}
          <div className="mb-24 flex flex-col space-y-6">
            {Object.entries(groupedRisks).map(([category, risks]) => (
              <div key={category} className="space-y-3">
                <div className="mb-5 mt-2 flex items-center justify-between text-gray-600">
                  <div className="flex flex-grow items-center">
                    <MantineTooltip
                      label={`See all ${category ?? ''} related risks`}
                      position="right"
                      offset={10}
                      disabled={!isCategoryValid(category)}
                    >
                      <Text
                        size="md"
                        fw={500}
                        ml="sm"
                        inherit
                        className={
                          isCategoryValid(category) ? 'cursor-pointer' : ''
                        }
                        onClick={() => {
                          if (isCategoryValid(category)) {
                            dispatch(
                              setSelectedCategoryFilter(
                                risks?.[0].risk_category.id ?? null
                              )
                            )
                          }
                        }}
                      >
                        {category}
                      </Text>
                    </MantineTooltip>
                    <div className="mx-4 flex-grow">
                      <hr className="border-t border-gray-300" />
                    </div>
                  </div>

                  {risks?.[0]?.risk_category?.sources ? (
                    <CategorySourcesDropdown
                      categorySources={risks[0].risk_category.sources}
                    />
                  ) : null}
                </div>
                {Array.isArray(risks) &&
                  risks.map((risk) => (
                    <RiskReviewCard
                      key={risk.id}
                      documents={documents ?? []}
                      data-testid={risk.id}
                      projectRisk={risk}
                    />
                  ))}
              </div>
            ))}
          </div>
        </div>
      )
    }
    return ProjectRisks

    // ESLint has no idea what it's talking about, do not add RiskListHeader as a dependency.
    // eslint-disable-next-line
  }, [risksToDisplay, documents])

  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  return (
    <div className="flex h-full flex-col bg-[#e5e7eb]">
      <div className="scrollbar-default scrollbar-thumb-gray-400 scrollbar-track-gray-100 hover:scrollbar-thumb-gray-500 mr-1 flex h-[calc(100%-48px)] flex-col overflow-x-hidden overflow-y-hidden">
        {documentProcessingCount > 0 ? (
          <ProcessingDocumentsNotice />
        ) : projectRisks &&
          risksToDisplay.length === 0 &&
          documentProcessingCount === 0 ? (
          <NoProjectRisks />
        ) : !projectRisks ? (
          <ProjectRisksLoading />
        ) : (
          <MemoizedProjectRisks />
        )}
      </div>
      <Tooltip id={'risk-info-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default RiskReviewPage
